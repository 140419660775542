.screenStyle {
  position: fixed;
  top: 5em;
  left: 2%;
  right: 2%;
  bottom: 2%;
}

.button {
  width: 40px;
  font-size: 90%;
  margin: 5px;
}

.lists-layout {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.tiledMovies {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
