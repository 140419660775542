.textBox {
  width: 80%;
  justify-content: left;
  background-color: transparent;
}

.wordlistTitle {
  font-size: 12px;
  margin-top: 20px;
}

.outerfiltermenu {
  height: 50px;
  background: #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #444444;
  margin-top: 0px;
  margin-bottom: 0px;
}

.savefilterSelect {
  width: 180px;
}

.savefilterblock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.savefilterText {
  margin-left: 0x;
  margin-bottom: 10px;
  margin-top:10px;
  margin-right: 10px;
  color: rgba(0, 0, 0, .87);
  /*font-family: "Roboto", "Helvetica", "Arial", "sans-serif";*/
  font-size: 14px;
}

.savefiltermenu {
  background: #eeeeee; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  height: 50px;
}

.savefiltermenuM {
  background: #eeeeee; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  height: 80px;
}



.leftsavefiltermenu {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
}

.leftsavefiltermenuM {
  margin-left: 0px;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: #444444;
}

.leftfiltermenu {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
}

.rightfiltermenu {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  color: #444444;
}
.netflixselect {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, .87);
  /*font-family: "Roboto", "Helvetica", "Arial", "sans-serif";*/
  font-size: 0.875rem;
  letter-spacing: 0.02857em;

}

.englishselect {
  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, .87);
  /*font-family: "Roboto", "Helvetica", "Arial", "sans-serif";*/
  font-size: 0.875rem;
  letter-spacing: 0.02857em;

}

.genreFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 300px;
  display: none
}

.yearFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 300px;
  display: none
}

.yearFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;



}

.yearFiltersM {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 300px;
  display: none
}

.filterHeading {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.buttonfooter {
  display: flex;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}


.swearFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 50px;
  top: 300px;
  display: none
}


.swearFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.swearFiltersM {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 300px;
  display: none
}


.ratingFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 150px;
  top: 300px;
  display: none
}

.ratingFiltersM {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 300px;
  display: none
}


.ratingFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}


.selectFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 710px;
  top: 300px;
  display: none
}

.selectFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;


}

.drawerMenus {
  padding-right: 0px;
}

.ageratingFilters {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 290px;
  top: 300px;
  display: none
}

.ageratingFiltersM {
  width: 300px;
  background: #cccccc;
  border-radius: 10px;
  padding-left: 10px;
  padding-right: 0px;
  margin: auto;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  box-shadow: 0 10px 10px rgba(0, 0, 0, .3);
  overflow: hidden;
  position: absolute;
  z-index: 100;
  left: 10px;
  top: 300px;
  display: none
}

.ageratingFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.genreFiltersDrawer {
  width: 300px;
 
  margin-left: 10px;
  margin-right: 10px;
 
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.slide-block {
  width: 100%;
  background: #cccccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  margin: 20px;
}


.slide-block-element {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}


.slide-block-title {
  display: flex;
  margin-bottom: 0px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
 
}

.slide-block-exclude {
  display: flex;
  margin-top: -20px;
  margin-bottom: 5px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.slide-block-title-p1 {
 margin-right: 5px

}


.word-block-element {
  margin-top: 5px;
  width: 100%;
  
}

.slide-block-grid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  
}

.slide-block-grid-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #444444;
  margin-right: -20px;
  margin-left: -10px;
  margin-top: -25px;
  margin-bottom:-25px
}

.slide-block-grid-val-minmax {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 12px;
}

.slide-block-grid-val {
  text-align: center;
  margin-left: 0px;
  margin-right: 0px;
}

.slide-block-grid-val-min {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}

.slide-block-grid-val-max {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
}



.slide-block-element h4 {

  margin-bottom: 40px;
}

/*
.slide-block-title {
  margin-bottom: 40px;
}*/

.slide-block-element-input {
  width: 80;
}

.slide-block p {
  width: 100%;
  margin: 5px auto;
  font-size: 15px;
  font-style: italic;
  text-align: center;
}